import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import usePlayerStore from '../store/playerStore';
import { default as Grid } from '@mui/material/Unstable_Grid2/Grid2';
import { formatDate, formatTime } from '../util/dateTimeFormats';
import useLiveStore from '../store/liveStore';

type useAgendaProps = {
  stage: 'preview' | 'live' | 'marketing';
  variant?: 'Component' | 'Widget';
};

export default function useAgenda(props: useAgendaProps) {
  // props
  const { stage, variant = 'Component' } = props;

  // global store
  const { agenda, isPreview, playerUi } = usePlayerStore();

  const { liveDataSession } = useLiveStore();
  console.log('##!##-liveDataSession', liveDataSession);

  const {
    actorDivider,
    timeFormat,
    dateFormat,
    agendaHeadline,
    slotTitleInSecondaryColor,
  } = playerUi;

  console.log('##!##-agendaHeadline', agendaHeadline);

  //
  const getActorDivider = () => {
    return actorDivider ? '' + actorDivider + '' : ' | ';
  };

  // set the mode to display
  const agendaStage = isPreview ? 'preview' : stage;

  // get slots for session
  const getSessionSlots = (sessionId: string) => {
    const sessionSlots = agenda[agendaStage].sessions.filter(
      (ses: any, index: number) => ses?.[0].sessionId === sessionId,
    );

    if (sessionSlots && sessionSlots[0].length > 0) {
      return sessionSlots[0];
    }

    return undefined;
  };

  // segment the slots into columns
  const getSegmentedSlots = (sessionId: string) => {
    const slots = getSessionSlots(sessionId);

    const segments: any[] = [];
    let segment: any[] = [];

    slots.forEach((slot: any, index: number) => {
      segment.push(slot);

      if (variant !== 'Widget') {
        if (slot.newColumnAfter || index === slots.length - 1) {
          segments.push([...segment]);
          segment = [];
        }
      } else if (index === slots.length - 1) {
        segments.push([...segment]);
      }
      // }
    });
    console.log('segments', segments);
    return segments;
  };

  const buildAgendaHtml = () => {
    if (!agenda || !agenda[agendaStage] || !agenda[agendaStage].event) {
      return <></>;
    }

    return (
      <>
        <style>{`
            .bs_agenda.sessionGridContainer {
              display: grid;
              grid-template-columns: [first] auto [second] 1fr;
              column-gap: 16px;
              width: 100%
            }

            .bs_agenda.sessionGridItemDate {
              grid-column-start: 1;
            }

            .bs_agenda.sessionGridItemContent {
              grid-column-start: 2;
            }

            .bs_agenda.slotGridContainer {
              display: grid;
              grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
              width: 100%
              column-gap: 24px;
            }

            .bs_agenda.slotColumn {}

            .bs_agenda.slotGrid {
              display: grid;
              grid-template-columns: [first] auto [second] 1fr;
              grid-template-rows: min-content;
              column-gap: 16px;
              width: 100%
            }
        `}</style>

        {agenda[agendaStage].event.map((session: any, index: number) => {
          return (
            <>
              <>
                <Box sx={{ mt: '1rem' }}>
                  {/* <Typography variant="body1" sx={{ mb: 1 }}>
                    {liveDataSession?.[0].sceneData.agendaHeadline &&
                    liveDataSession[0].sceneData.agendaHeadline !== ''
                      ? liveDataSession[0].sceneData.agendaHeadline
                      : agendaHeadline}
                  </Typography> */}
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontWeight: 'bold' }}
                  >
                    {agendaHeadline
                      ? agendaHeadline
                      : liveDataSession[0].sceneData.agendaHeadline}
                  </Typography>

                  {playerUi.multiSession && session.showInAgenda && (
                    <div className="bs_agenda sessionGridContainer">
                      <div className="bs_agenda sessionGridItemDate">
                        <Typography variant="body1">
                          {formatDate(dateFormat, session.startdate)}
                        </Typography>
                      </div>
                      <div className="bs_agenda sessionGridItemContent">
                        <Stack mb={2}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', pb: 1 }}
                          >
                            {session.title}
                          </Typography>

                          {session.showDescriptionInAgenda && (
                            <Typography variant="body1" sx={{ pb: 1 }}>
                              {session.description}
                            </Typography>
                          )}

                          {session.showActorsInAgenda &&
                            session.actors.map((actor: any) => {
                              return actor.displayActorInAgenda ? (
                                <Typography variant="body1">
                                  {(actor.firstname ? actor.firstname : '') +
                                    (actor.lastname
                                      ? ' ' + actor.lastname
                                      : '') +
                                    '' +
                                    (actor.title
                                      ? getActorDivider() + actor.title
                                      : '') +
                                    (actor.organisation
                                      ? getActorDivider() + actor.organisation
                                      : '')}
                                </Typography>
                              ) : (
                                <></>
                              );
                            })}
                        </Stack>
                      </div>
                    </div>
                  )}

                  <div className="bs_agenda slotGridContainer">
                    {getSegmentedSlots(session.id).map((segment, segIndex) => {
                      // return the columns
                      return (
                        // <div key={segIndex} className="bs_agenda slotColumn">
                        <div key={segIndex} className="bs_agenda slotGrid">
                          {segment.map((slot: any, slotIndex: number) => (
                            // return the slots
                            <>
                              {slot.showInAgenda && (
                                <>
                                  <Typography
                                    variant="body1"
                                    sx={{ textAlign: 'right' }}
                                  >
                                    {/* {new Date(slot.starttime).toLocaleTimeString(
                                    'de-DE',
                                    {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false,
                                    },
                                  )} */}
                                    {formatTime(timeFormat, slot.starttime)}
                                  </Typography>
                                  <Stack mb={1}>
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: 'bold', pb: 1 }}
                                      color={
                                        slotTitleInSecondaryColor
                                          ? 'secondary'
                                          : ''
                                      }
                                    >
                                      {slot.title}
                                    </Typography>

                                    {slot.showDescriptionInAgenda && (
                                      <Typography
                                        variant="body1"
                                        sx={{ pb: 1 }}
                                      >
                                        {slot.description}
                                      </Typography>
                                    )}

                                    {slot.showActorsInAgenda && (
                                      <Typography
                                        variant="body1"
                                        sx={{ pb: 1 }}
                                      >
                                        {slot.actors.map(
                                          (actor: any, actorIndex: number) =>
                                            actor.displayActorInAgenda ? (
                                              <Typography
                                                variant="body1"
                                                // sx={{ pb: 0 }}
                                                key={
                                                  'actor-' +
                                                  slot.id +
                                                  '-' +
                                                  actorIndex
                                                }
                                              >
                                                {(actor.firstname
                                                  ? actor.firstname + ' '
                                                  : '') +
                                                  (actor.lastname
                                                    ? actor.lastname + ''
                                                    : '') +
                                                  (actor.title
                                                    ? getActorDivider() +
                                                      actor.title +
                                                      ''
                                                    : '') +
                                                  (actor.organisation
                                                    ? getActorDivider() +
                                                      actor.organisation
                                                    : '')}
                                              </Typography>
                                            ) : (
                                              <></>
                                            ),
                                        )}
                                      </Typography>
                                    )}
                                  </Stack>
                                  {/* </div> */}
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </>
            </>
          );
        })}
      </>
    );
  };

  return buildAgendaHtml;
}

// const getActorDivider()
