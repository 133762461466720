import { create } from 'zustand';

type State = {
  [key: string]: any;
};

// init empty store
const useLiveStore = create<State>((set) => ({
  liveData: null,
  setLiveData: (data: any) => {
    set({ liveData: data });
    set({ liveDataSession: data.sceneData.sessions });
  },
  liveDataSession: null,
}));

export default useLiveStore;
