import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import usePlayerStore from '../store/playerStore';
import getEventPlayerUiApi from '../api/event/getEventPlayerUiApi';
import { useFeedback } from '../Components/Feedback/FeedbackContext';

// Layer II Data Handling
export function useEventPlayerUiQuery(eventId: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['player', 'ui'],
      queryFn: () => getEventPlayerUiApi({ eventId: eventId }),
    },
    queryClient,
  );
}

// Layer III Data Handling
export default function useEventPlayerUiQueryHook(eventId: string) {
  // feedback
  const { setFeedback } = useFeedback();

  // event query
  const { data, error, isLoading, isSuccess, isError, status } =
    useEventPlayerUiQuery(eventId);

  // event store
  const {
    setPlayerUi,
    setPlayerUiStatus,
    setRegistrationData,
    setOnlineStageData,
    setAgenda,
    setMarketingData,
  } = usePlayerStore();

  useEffect(() => {
    // update data
    if (data) {
      setPlayerUi(data.playerBranding);
      setRegistrationData(data.registrationData);
      setOnlineStageData(data.onlineStageData);
      setAgenda(data.agenda);
      setMarketingData(data.marketingData);
    }
  }, [data]);

  useEffect(() => {
    // update state
    setPlayerUiStatus(status);
  }, [status]);

  useEffect(() => {
    if (error) {
      setFeedback({ type: 'error', errorCode: error });
    }
  }, [error]);

  return { data, isLoading, isSuccess, isError };
}
