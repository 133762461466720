import Parse from 'parse';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Suspense, lazy, useEffect, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import useLiveStore from '../store/liveStore';
import useInterval from '../hooks/useInterval';
import useLivePlayerControl from '../hooks/useLivePlayerControl';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import setUserTrackingApi from '../api/user/setUserTrackingApi';

export default function PlayerContentLive() {
  // global store
  const { eventId, registrationData, playerUser } = usePlayerStore();

  // state
  const fullscreenEnabled = true;
  const [fullscreenActive, setFullscreenActive] = useState(false);

  // theme
  const theme = useTheme();

  // fullscreen handling
  const toggleFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      setFullscreenActive(false);

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setFullscreenActive(true);

      const element = document.getElementById('bs_Player_FullscreenContainer');
      if (element!.requestFullscreen) {
        element!.requestFullscreen();
      } else if (element!.mozRequestFullScreen) {
        element!.mozRequestFullScreen();
      } else if (element!.webkitRequestFullscreen) {
        element!.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
      } else if (element!.msRequestFullscreen) {
        element!.msRequestFullscreen();
      }
    }
  };

  const fullscreenChanged = () => {
    console.log('fullscreenChanged-called');
    if (document.fullscreenElement || document.webkitCurrentFullScreenElement) {
      console.log('fullscreenActive - true');
      setFullscreenActive(true);
    } else {
      console.log('fullscreenActive - false');
      setFullscreenActive(false);
    }
  };

  // player control
  const { sceneToDisplay } = useLivePlayerControl();

  // start tracking
  if (registrationData && playerUser && playerUser.email) {
    const trackingCall = async () => {
      setUserTrackingApi(eventId.eventId, playerUser.email);
    };

    useInterval(trackingCall, 60000);
  }

  //// side effects
  useEffect(() => {
    //// Init tracking

    // set fullscreen listener
    const element = document.getElementById('bs_Player_FullscreenContainer');
    // element.addEventListener('fullscreenchange', fullscreenChanged);
    element!.addEventListener('fullscreenchange', fullscreenChanged, false);
    element!.addEventListener('mozfullscreenchange', fullscreenChanged, false);
    element!.addEventListener('MSFullscreenChange', fullscreenChanged, false);
    element!.addEventListener(
      'webkitfullscreenchange',
      fullscreenChanged,
      false,
    );

    return () => {
      // remove event listener
      const element = document.getElementById('e21z_fullscreenContainer');
      if (element !== null && element !== undefined) {
        // element.removeEventListener = fullscreenChanged;
        element.removeEventListener(
          'fullscreenchange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'mozfullscreenchange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'MSFullscreenChange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'webkitfullscreenchange',
          fullscreenChanged,
          false,
        );
      }
    };
  }, []);
  return (
    <>
      <Box
        sx={{ width: '100%', color: theme.palette.playerBgColor.contrastText }}
      >
        <Box
          id="bs_Player_FullscreenContainer"
          // sx={{
          //     backgroundColor: live_player_bg_color !== "" ? "#"+live_player_bg_color+" !important":"",
          // }}
        >
          <Box
            className={'fullscreenEnabled'}
            sx={{
              position: fullscreenActive ? 'absolute' : 'relative',
              width: '100%',
              height: fullscreenActive ? '100vh' : 'unset',
              top: fullscreenActive ? '50%' : 'unset',
              transform: fullscreenActive ? 'translateY(-50%)' : 'unset',
              mt: 0,
            }}
          >
            <Suspense>{sceneToDisplay}</Suspense>

            {fullscreenEnabled && (
              <Box
                id="e21z_fullscreenToggleBar"
                sx={{
                  width: '100%',
                  textAlign: 'right',
                  // backgroundColor:
                  //   live_player_bg_color !== ''
                  //     ? '#' + live_player_bg_color + ' !important'
                  //     : '',
                  position: fullscreenActive ? 'fixed' : 'static',
                  bottom: fullscreenActive ? '0' : 'unset',
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1">
                    {fullscreenActive
                      ? 'Vollbild ausschalten'
                      : 'Vollbild einschalten'}
                  </Typography>

                  <IconButton
                    onClick={toggleFullscreen}
                    style={{
                      textAlign: 'right',
                    }}
                    color="primary"
                  >
                    {fullscreenActive ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </IconButton>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* <video
        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        width="750"
        height="500"
        controls
      ></video> */}
    </>
  );
}

// import Parse from 'parse';
// import { Box } from '@mui/material';
// import { Suspense, lazy, useEffect, useState } from 'react';
// import usePlayerStore from '../store/playerStore';
// import useLiveStore from '../store/liveStore';
// import useInterval from '../hooks/useInterval';

// export default function PlayerContentLive() {
//   //
//   const [actualSubscription, setActualSubscription] =
//     useState<Parse.LiveQuerySubscription | null>(null);

//   const [contentToDisplay, setContentToDisplay] = useState(<></>);

//   // stores
//   const {
//     eventId,
//     registrationData,
//     onlineStageData,
//     playerUser,
//     setPlayerUser,
//   } = usePlayerStore();

//   const { liveData, setLiveData } = useLiveStore();

//   // Scene Components
//   const RegistrationForm = lazy(
//     () => import('../Components/RegistrationForm/RegistrationForm'),
//   );

//   const WelcomeScreen = lazy(
//     () => import('../Components/PlayerContentWelcome'),
//   );

//   const LiveStage = lazy(() => import('../Components/PlayerContentLiveStage'));

//   ////
//   const liveStateUpdate = () => {
//     if (playerUser || !registrationData.useRegistration) {
//       if (
//         new Date().getTime() >= onlineStageData.starttimeLobby.getTime() &&
//         contentToDisplay.props.type !== 'LiveStage'
//       ) {
//         setContentToDisplay(<LiveStage type="LiveStage" />);
//       }
//     }
//   };

//   useInterval(() => {
//     liveStateUpdate();
//   }, 1000);

//   useEffect(() => {
//     // wrapper for async subscription handling
//     const fetchSubscription = async () => {
//       // build query
//       const liveQuery = new Parse.Query('PlayerLive');
//       liveQuery.equalTo('event', {
//         __type: 'Pointer',
//         className: 'Event',
//         objectId: eventId.eventId,
//       });

//       // initial query call
//       liveQuery
//         .first()
//         .then((res) => {
//           if (res) {
//             setLiveData(res.toJSON());
//           }
//         })
//         .catch((e) => console.error(e));

//       // subscribe to the livequery
//       const res = await liveQuery.subscribe();

//       // set the event handlers which will trigger the update of global store
//       res.on('create', (object) => {
//         console.log('object created', object);
//         setLiveData(object.toJSON());
//       });

//       res.on('update', (object) => {
//         console.log('object updated', object);
//         setLiveData(object.toJSON());
//       });

//       res.on('enter', (object) => {
//         console.log('object updated', object);
//         setLiveData(object.toJSON());
//       });

//       // save the subscription to state
//       setActualSubscription(res);
//     };

//     fetchSubscription();

//     // check which view to display on startup
//     // if registration should be used
//     if (registrationData.useRegistration) {
//       // read local user data
//       const localUserData = localStorage.getItem(
//         'bsLocalUser-' + eventId.eventId,
//       );

//       // check if there is a user in localstore
//       if (playerUser) {
//         setContentToDisplay(<WelcomeScreen />);
//       } else {
//         if (localUserData) {
//           setPlayerUser(JSON.parse(localUserData));
//           setContentToDisplay(<WelcomeScreen />);
//         } else {
//           setContentToDisplay(<RegistrationForm />);
//         }
//       }
//     } else {
//       setContentToDisplay(<WelcomeScreen />);
//     }

//     return () => {
//       // unsubscribe from the livequery on unmount
//       actualSubscription?.unsubscribe();
//     };
//   }, []);

//   // on changes to playerUser
//   useEffect(() => {
//     if (playerUser) {
//       setContentToDisplay(<WelcomeScreen />);
//     }
//   }, [playerUser]);

//   return (
//     <>
//       <Box sx={{ width: '100%' }}>
//         <Suspense>{contentToDisplay}</Suspense>
//       </Box>
//       {/* <video
//         src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
//         width="750"
//         height="500"
//         controls
//       ></video> */}
//     </>
//   );
// }
