type MediaEmbedNormalProps = {
  code: string;
};

export default function MediaEmbedNormal(props: MediaEmbedNormalProps) {
  // props
  const { code } = props;

  // prepare the code
  const finalCode = code.trim();

  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'scroll',
        }}
        dangerouslySetInnerHTML={{ __html: finalCode }}
      ></div>
    </>
  );
}
