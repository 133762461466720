import {
  Box,
  Container,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import usePlayerStore from '../store/playerStore';
import { useLocation } from 'react-router-dom';
import { formatDate, formatTime } from '../util/dateTimeFormats';

export default function PlayerHeader() {
  // theme
  const theme = useTheme();

  // mediaQuery
  // mobile
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  // tablet
  const mediaQueryTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  // url params
  const location = useLocation();

  // eventUiData
  const { playerUi } = usePlayerStore();

  const { dateFormat, timeFormat, eventStartDateTime } = playerUi;

  return (
    <>
      {playerUi.bannerImg && playerUi.bannerImg !== '' && (
        <Box
          id="bs_Player_Header_BgImageBlur"
          sx={{
            display: mediaQuery ? 'none' : mediaQueryTablet ? 'none' : 'block',
            mb: 0,
            width: '100vw',
            height: {
              xs: '220px',
              md: '300px',
            },
            left: 0,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <img
            src={playerUi.bannerImg}
            alt="Event thumbnail"
            style={{
              width: '140%',
              height: '140%',
              objectFit: 'cover',
              filter: 'blur(20px)',
              margin: '-40px -80px -80px -40px',
            }}
          />
        </Box>
      )}

      <Container
        sx={{
          px: mediaQuery ? 0 : mediaQueryTablet ? '0px !important' : '16px',
        }}
      >
        {/* Logo display */}
        {playerUi.bannerImg && playerUi.bannerImg !== '' && (
          <Box
            id="bs_Player_Header_BgImage"
            sx={{
              mt: {
                xs: '0px',
                // xs: '-180px',
                // sm: '-260px',
                md: '-260px',
              },
              height: {
                // xs: '180px',
                xs: 'unset',
                // sm: '260px',
                md: '260px',
              },
              width: '100%',
              position: 'relative',
            }}
          >
            <img
              src={playerUi.bannerImg}
              alt="Event thumbnail"
              style={
                mediaQuery
                  ? { width: '100%' }
                  : mediaQueryTablet
                    ? { width: '100%' }
                    : {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }
              }
            />
          </Box>
        )}

        {playerUi.bannerImg &&
          playerUi.bannerImg !== '' &&
          playerUi.logo &&
          playerUi.logo !== '' && (
            <Stack direction="row">
              <img
                id="bs_Player_Header_Logo"
                alt=""
                src={playerUi.logo}
                width="auto"
                height={
                  mediaQuery ? '50px' : mediaQueryTablet ? '70px' : '90px'
                }
                style={{
                  marginLeft: mediaQuery
                    ? '40px'
                    : mediaQueryTablet
                      ? '40px'
                      : '20px',
                  marginTop: mediaQuery
                    ? '-44px'
                    : mediaQueryTablet
                      ? '-58px'
                      : '-78px',
                  zIndex: 1,
                  padding: mediaQuery ? '2px' : '4px',
                  backgroundColor: '#fff',
                  border: '1px solid #',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 0px',
                }}
              />
            </Stack>
          )}

        {/* {data?.attributes.thumbnail_display_when_live && (
          <Stack direction="row">
            {images.map((img, index) => {
              return (
                <img
                  key={index}
                  alt=""
                  src={img}
                  width="auto"
                  height="90px"
                  style={{
                    marginLeft: '20px',
                    marginTop: '-78px',
                    zIndex: 1,
                    padding: '4px',
                    backgroundColor: '#fff',
                    border: '1px solid #',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 0px',
                  }}
                />
              );
            })}
          </Stack>
        )} */}

        {mediaQuery ? (
          <>
            <Typography
              id="bs_Player_Header_Headline"
              variant="h5"
              component="h2"
              color={theme.palette.playerBgColor.contrastText}
              sx={{
                mt:
                  playerUi.bannerImg &&
                  playerUi.bannerImg !== '' &&
                  playerUi.logo &&
                  playerUi.logo !== ''
                    ? 2
                    : 0,
                pt:
                  playerUi.bannerImg &&
                  playerUi.bannerImg !== '' &&
                  playerUi.logo &&
                  playerUi.logo !== ''
                    ? 0
                    : 2,
                pb: playerUi.dateInTitle ? 0 : 2,
                px: '16px',
                fontWeight: 'bold',
                // color:
                // live_header_typo_color !== ''
                //   ? '#' + live_header_typo_color + ' !important'
                //   : '',
              }}
            >
              {playerUi.title}
            </Typography>

            {(location.pathname === '/' ||
              location.pathname === '/registration' ||
              location.pathname === '/confirmation' ||
              location.pathname === '/welcome') &&
              playerUi.dateInTitle && (
                <Typography
                  id="bs_Player_Header_Headline"
                  variant="h5"
                  component="h2"
                  color={theme.palette.playerBgColor.contrastText}
                  sx={{
                    mt: 0,
                    pt:
                      playerUi.bannerImg &&
                      playerUi.bannerImg !== '' &&
                      playerUi.logo &&
                      playerUi.logo !== ''
                        ? 0
                        : 2,
                    pb: 2,
                    px: '16px',
                    fontWeight: 'bold',
                    // color:
                    // live_header_typo_color !== ''
                    //   ? '#' + live_header_typo_color + ' !important'
                    //   : '',
                  }}
                >
                  {
                    formatDate(dateFormat, eventStartDateTime) +
                      // new Date(playerUi.eventStartDateTime).toLocaleDateString(
                      //   'de',
                      //   {
                      //     day: 'numeric',
                      //     month: 'long',
                      //     year: 'numeric',
                      //   },
                      // )

                      ' um ' +
                      formatTime(timeFormat, eventStartDateTime)
                    // new Date(playerUi.eventStartDateTime).toLocaleTimeString(
                    //   'de',
                    //   {
                    //     hour: '2-digit',
                    //     minute: '2-digit',
                    //   },
                    // )
                    // +' Uhr'
                  }
                </Typography>
              )}
          </>
        ) : (
          <Typography
            id="bs_Player_Header_Headline"
            variant="h3"
            component="h2"
            color={theme.palette.playerBgColor.contrastText}
            sx={{
              mt:
                playerUi.bannerImg &&
                playerUi.bannerImg !== '' &&
                playerUi.logo &&
                playerUi.logo !== ''
                  ? 2
                  : 0,
              pt:
                playerUi.bannerImg &&
                playerUi.bannerImg !== '' &&
                playerUi.logo &&
                playerUi.logo !== ''
                  ? 0
                  : 2,
              pb: 2,
              fontWeight: 'bold',
              px: mediaQueryTablet ? '16px' : 'unset',
              // color:
              // live_header_typo_color !== ''
              //   ? '#' + live_header_typo_color + ' !important'
              //   : '',
            }}
          >
            {location.pathname === '/' ||
            location.pathname === '/registration' ||
            location.pathname === '/confirmation' ||
            location.pathname === '/welcome'
              ? playerUi.title +
                '' +
                (playerUi.dateInTitle
                  ? playerUi.dividerTitle +
                    '' +
                    formatDate(dateFormat, eventStartDateTime) +
                    // new Date(playerUi.eventStartDateTime).toLocaleDateString(
                    //   'de',
                    //   {
                    //     day: 'numeric',
                    //     month: 'long',
                    //     year: 'numeric',
                    //   },
                    // )

                    ' um ' +
                    formatTime(timeFormat, eventStartDateTime)
                  : // new Date(playerUi.eventStartDateTime).toLocaleTimeString(
                    //   'de',
                    //   {
                    //     hour: '2-digit',
                    //     minute: '2-digit',
                    //   },
                    // )
                    // +' Uhr'
                    '')
              : playerUi.title}
          </Typography>
        )}
      </Container>
    </>
    // <Stack
    //   id="bs_Player_Header"
    //   direction="row"
    //   sx={{
    //     minHeight: '60px',
    //     width: '100%',
    //     justifyContent: 'flex-start',
    //     alignItems: 'center',
    //   }}
    // >
    //   <Typography>{playerUi.title}</Typography>
    // </Stack>
  );
}
