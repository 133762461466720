import { QueryClient } from '@tanstack/react-query';
import './util/i18n';
import { ReactNode, useEffect, useState } from 'react';
import { useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { useFeedback } from './Components/Feedback/FeedbackContext';
import setConfig from './util/setConfig';
import { CssBaseline, Theme, ThemeProvider, useTheme } from '@mui/material';
import routesConfig from './Routes/routesConfig';
import useEventPlayerUiQueryHook from './data/playerUi.query';
import usePlayerStore from './store/playerStore';
import setupTheme from './theme/basic';
import { themeOptions } from './theme/basic/themeBasics';

export default function App() {
  // props

  // state
  const [configSet, setConfigSet] = useState(false);

  // url search params
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const preview = searchParams.get('preview');
  const sessionToken = searchParams.get('pSessionToken');

  // set preview flag
  const { setIsPreview, setSessionToken } = usePlayerStore();

  // feedback
  const { setFeedback } = useFeedback();

  // routes
  const playerRoutes = useRoutes(routesConfig());

  //// side effects
  useEffect(() => {
    if (preview) setIsPreview(true);
    if (sessionToken) setSessionToken(sessionToken);
  }, []);

  useEffect(() => {
    if (eventId) {
      // bootstrap the app
      setConfig({ eventId: eventId })
        .then(async (res) => {
          setConfigSet(true);
        })
        .catch((e) => {
          console.error(e);
          setFeedback({
            type: 'error',
            errorCode: e,
          });
        });
    } else {
      // no eventId, display error
      setFeedback({
        type: 'error',
        errorCode: new Error('No eventId found'),
      });
    }
  }, [eventId]);

  return <>{configSet && <ThemeWrapper>{playerRoutes}</ThemeWrapper>}</>;
}

type ThemeWrapperProps = { children: ReactNode };

function ThemeWrapper(props: ThemeWrapperProps) {
  // props
  const { children } = props;

  // theme
  const defaultTheme = useTheme();

  // state
  const [theme, setTheme] = useState<Theme | null>();

  // global store
  const { eventId, playerUi } = usePlayerStore();

  // get the player ui values (stored in global state)
  useEventPlayerUiQueryHook(eventId.eventId);

  ////
  useEffect(() => {
    if (playerUi && !theme) {
      const playerTheme = setupTheme({
        primaryColor: playerUi.primaryColor,
        secondaryColor: playerUi.secondaryColor,
        playerBgColor: defaultTheme.palette.augmentColor({
          color: {
            main: playerUi.bgColor
              ? playerUi.bgColor
              : themeOptions.palette?.background?.default,
          },
          name: 'playerBgColor',
        }),
        fontSize: playerUi.textSize,
      });

      setTheme(playerTheme);
    }
  }, [playerUi]);

  return (
    <>
      {theme && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      )}
    </>
  );
}
