import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';

export default function PlayerContentLobby() {
  // global store
  const { playerUi, onlineStageData } = usePlayerStore();

  const { showEventText, showAgenda, showLobbyText } = onlineStageData;

  // theme
  const theme = useTheme();

  // get Agenda
  const Agenda = useAgenda({ stage: 'live' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {showEventText &&
        // <Typography
        //   component="div"
        //   dangerouslySetInnerHTML={{ __html: playerUi.descriptionHtml }}
        // />
        rewriteHtml(playerUi.descriptionHtml)}
      {/* <Typography
        component="div"
        dangerouslySetInnerHTML={{ __html: onlineStageData.playerText }}
      /> */}
      {showLobbyText && rewriteHtml(onlineStageData.playerText)}

      {
        showAgenda && <Agenda />

        // rewriteHtml(playerUi.agendaHtml)
      }
    </Box>
  );
}
