import { useForm } from 'react-hook-form';
import usePlayerStore from '../../store/playerStore';
import RegistrationFormLayout from './RegistrationFormLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  registrationDefaults,
  registrationValSchema,
} from './registrationValidationSchema';
import { Box } from '@mui/material';
import { RegistrationFormTypes } from './registrationFormTypes';
import { useMutation } from '@tanstack/react-query';
import setEventPlayerUserApi from '../../api/user/setEventPlayerUserApi';
import validateEventAccessApi from '../../api/event/validateEventAccessApi';

export default function RegistrationForm() {
  // params

  // global store
  const {
    eventId,
    playerUser,
    setPlayerUser,
    registrationData,
    onlineStageData,
    isPreview,
  } = usePlayerStore();

  // data mutation
  const setEventPlayerUser = useMutation({
    mutationFn: (data) =>
      setEventPlayerUserApi({ data: data, eventId: eventId }),
    onSuccess: (data, variables, context) => {
      localStorage.setItem(
        'bsLocalUser-' + eventId.eventId,
        JSON.stringify(data),
      );
      setPlayerUser(data.attributes);
    },
    onError: (error, variables, context) => {},
  });

  // extend reistration data for validation
  let extendedRegistrationDefaults = {
    ...registrationDefaults,
    fieldDefinition: registrationData.fieldDefinition,
    accessCodeRequired: onlineStageData.accessCodeRequired,
    // accessCode: '',
  };

  // check if we have already user data from localStore
  if (playerUser)
    extendedRegistrationDefaults = {
      ...playerUser,
      fieldDefinition: registrationData.fieldDefinition,
      accessCodeRequired: onlineStageData.accessCodeRequired,
      // accessCode: '',
    };

  // react hook form
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: extendedRegistrationDefaults,
    resolver: yupResolver(registrationValSchema),
  });

  console.log('errors', errors);

  // functions
  const handleFormSubmit = async (data: RegistrationFormTypes) => {
    const accessCode = data.accessCode;

    //
    let accessCodeCheck = false;
    let accessToken: string | Error;

    // check accescode
    if (!isPreview) {
      if (onlineStageData.accessCodeRequired) {
        console.log('handleFormSubmit1', data);
        accessToken = await validateEventAccessApi({
          accessCode: accessCode!,
        }).catch((e) => {
          console.error(e);
          return e;
        });

        if (typeof accessToken === 'string' && accessToken !== '') {
          accessCodeCheck = true;
          // setPlayerUser({ accessToken: accessToken });
          delete data.accessCode;

          data.accessToken = accessToken;

          setEventPlayerUser.mutate(data as any);
        } else {
          accessCodeCheck = false;
          setValue('accessCode', '');
          trigger();
        }
      } else {
        console.log('handleFormSubmit2', data);
        delete data.accessCode;
        setEventPlayerUser.mutate(data as any);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, pt: 4, pb: 4 }}>
      <Box maxWidth="480px">
        <RegistrationFormLayout
          handleSubmit={handleSubmit}
          handleFormSubmit={handleFormSubmit}
          control={control}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
        />
      </Box>
    </Box>
  );
}
