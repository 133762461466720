import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type State = {
  [key: string]: any;
};

// init empty store
const usePlayerStore = create<State>((set) => ({
  eventId: null,
  setEventId: (data: any) => set({ eventId: data }),

  connectionData: null,
  setConnectionData: (data: any) => set({ connectionData: data }),

  isPreview: false,
  setIsPreview: (data: any) => set({ isPreview: data }),

  sessionToken: null,
  setSessionToken: (data: any) => set({ sessionToken: data }),

  playerUi: null,
  setPlayerUi: (data: any) => set({ playerUi: data }),
  registrationData: null,
  setRegistrationData: (data: any) => set({ registrationData: data }),
  onlineStageData: null,
  setOnlineStageData: (data: any) => set({ onlineStageData: data }),
  agenda: null,
  setAgenda: (data: any) => set({ agenda: data }),
  marketingData: null,
  setMarketingData: (data: any) => set({ marketingData: data }),
  playerUiStatus: 'pending',
  setPlayerUiStatus: (status: QueryStatus) => set({ playerUiStatus: status }),

  playerUser: null,
  setPlayerUser: (data: any) =>
    set((state) => ({ playerUser: { ...state.playerUser, ...data } })),
}));

export default usePlayerStore;
