import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';

export default function PlayerContentConfirmation() {
  // global store
  const { playerUi, registrationData } = usePlayerStore();

  // theme
  const theme = useTheme();

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {registrationData.showEventTextConfirmation &&
        // <Typography
        //   component="div"
        //   dangerouslySetInnerHTML={{ __html: playerUi.descriptionHtml }}
        // />
        rewriteHtml(playerUi.descriptionHtml)}
      {/* <Typography
        component="div"
        dangerouslySetInnerHTML={{ __html: registrationData.confirmationText }}
      /> */}
      {rewriteHtml(registrationData.confirmationText)}
      {/* <ConfirmationForm /> */}
      Confirmation Form
      {registrationData.showAgendaConfirmation &&
        // <Typography
        //   component="div"
        //   dangerouslySetInnerHTML={{ __html: playerUi.agendaHtml }}
        // />
        rewriteHtml(playerUi.agendaHtml)}
    </Box>
  );
}
