import { PaletteColor, ThemeOptions, createTheme } from '@mui/material';
import { merge } from 'lodash';

import { themeOptions } from './themeBasics';
import * as ComponentOverrides from './Components';

type setupThemeParams = {
  primaryColor: string;
  secondaryColor: string;
  playerBgColor: PaletteColor;
  fontSize: 'small' | 'medium' | 'large';
};

/**
 * Create Mui theme from defined theme basics & overrides
 * @returns Mui theme object
 */
export default function setupTheme(params: setupThemeParams) {
  // params
  const { primaryColor, secondaryColor, playerBgColor, fontSize } = params;

  const updateThemeOptions = themeOptions;

  if (primaryColor && primaryColor !== '')
    updateThemeOptions.palette!.primary = { main: primaryColor };

  if (secondaryColor && secondaryColor !== '')
    updateThemeOptions.palette!.secondary = { main: secondaryColor };

  if (playerBgColor) {
    // updateThemeOptions.palette!.background!.default = playerBgColor.main;
    updateThemeOptions.palette!.playerBgColor = playerBgColor;
  }

  // set fontsize
  updateThemeOptions.typography = {
    ...updateThemeOptions.typography,
    ...fontSizes[fontSize ? fontSize : 'medium'],
  };

  // create basic theme
  const theme = createTheme({
    ...updateThemeOptions,
  });

  // merge the individual overrides
  const compOverrides: any = merge(
    // ComponentOverrides.MuiCssBaseline(),
    ComponentOverrides.MuiIconButton(),
    ComponentOverrides.MuiTabs(theme),
    ComponentOverrides.MuiButton(theme),
    ComponentOverrides.MuiDialog(theme),
    ComponentOverrides.MuiAppBar(theme),
    ComponentOverrides.MuiDrawer(theme),
    ComponentOverrides.MuiTypography(theme),
    ComponentOverrides.MuiModal(theme),
    ComponentOverrides.MuiAccordion(theme),
  );

  theme.components = compOverrides;

  // return the theme
  return theme;
}

const fontSizes = {
  small: {
    // htmlFontSize: 14,
    // fontSize: 12,
    body1: {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: 1.2,
      letterSpacing: '0.005em',
    },
    button: { fontSize: '0.725em' },
  },
  medium: {
    // htmlFontSize: 16,
    // fontSize: 14,
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.2,
      letterSpacing: '0.005em',
    },
    button: { fontSize: '0.875em' },
  },
  large: {
    // htmlFontSize: 18,
    // fontSize: 16,
    body1: {
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.2,
      letterSpacing: '0.005em',
    },
    button: { fontSize: '1em' },
  },
};
