import { MutableRefObject, useEffect, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import { useFeedback } from '../Components/Feedback/FeedbackContext';
import useLiveStore from '../store/liveStore';
import { useLocation, useNavigate } from 'react-router-dom';
import useInterval from './useInterval';

export default function useViewControl() {
  // player store
  const {
    playerUi,
    registrationData,
    setPlayerUser,
    playerUser,
    onlineStageData,
    isPreview,
  } = usePlayerStore();

  const { liveData } = useLiveStore();

  // state
  const [vcInit, setVcInit] = useState(false);
  const [currentView, setCurrentView] = useState('/');

  //   const [registrationIntervalRef, setRegistrationIntervalRef] =
  //     useState<NodeJS.Timeout | null>();
  //   console.log('registrationIntervalRef', registrationIntervalRef);

  const [registrationOpen, setRegistrationOpen] = useState(
    new Date().getTime() >= registrationData?.registrationStarts.getTime(),
  );
  const [lobbyOpen, setLobbyOpen] = useState(
    new Date().getTime() >= onlineStageData.starttimeLobby.getTime(),
  );

  const [userValid, setUserValid] = useState(false);

  // feedback
  const { setFeedback } = useFeedback();

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  // redirect checks
  const redirectHandler = () => {
    if (!isPreview) {
      console.log('redirect Called!');

      const urlSearchParams = new URLSearchParams(location.search);

      console.log('#!#!-CLOSED-CHECK');
      // CLOSED VIEW - check if event ended
      if (playerUi.eventEnded || liveData?.closed) {
        // if event is already closed or get's closed by admin action
        // redirect to view CLOSED
        location.pathname !== '/closed' &&
          navigate('/closed' + location.search);
        setCurrentView('closed');
        return 'closed';
      }

      console.log('#!#!-LIVE-CHECK');
      // LIVE VIEW
      // !!! missing check if a session was selected or is playing
      // !!! need to check for lobby & multisession???
      if (registrationData) {
        // registration is active
        if (playerUi.multiSession) {
          // it's a multisession event
          if (
            //   playerUser?.userConfirmed &&
            userValid &&
            playerUser?.cookiesAccepted &&
            (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
            playerUser?.sessionSelected &&
            lobbyOpen
          ) {
            location.pathname !== '/live' &&
              navigate('/live' + location.search);
            setCurrentView('live');
            return 'live';
          }
        } else {
          // it's a single session event
          if (
            userValid &&
            playerUser?.cookiesAccepted &&
            (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
            liveData?.sceneData?.sessions.some(
              (session: any) => session.status === 'play',
            )
          ) {
            location.pathname !== '/live' &&
              navigate('/live' + location.search);
            setCurrentView('live');
            return 'live';
          }
        }
      } else {
        if (playerUi.multiSession) {
          if (
            playerUser?.cookiesAccepted &&
            (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
            playerUser?.sessionSelected &&
            lobbyOpen
          ) {
            location.pathname !== '/live' &&
              navigate('/live' + location.search);
            setCurrentView('live');
            return 'live';
          }
        } else {
          if (
            playerUser?.cookiesAccepted &&
            (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
            liveData?.sceneData?.sessions.some(
              (session: any) => session.status === 'play',
            )
          ) {
            console.log('HERE!!!');

            location.pathname !== '/live' &&
              navigate('/live' + location.search);
            setCurrentView('live');
            return 'live';
          }
        }
      }

      console.log('#!#!-LOBBY-CHECK', playerUi.multiSession);
      // LOBBY VIEW
      if (registrationData) {
        if (
          playerUi.multiSession &&
          // playerUser?.userConfirmed &&
          userValid &&
          playerUser?.cookiesAccepted &&
          (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
          lobbyOpen
        ) {
          location.pathname !== '/lobby' &&
            navigate('/lobby' + location.search);
          setCurrentView('lobby');
          return 'lobby';
        }
      } else {
        if (
          playerUi.multiSession &&
          playerUser?.cookiesAccepted &&
          (!onlineStageData?.accessCodeRequired || playerUser?.accessToken) &&
          lobbyOpen
        ) {
          location.pathname !== '/lobby' &&
            navigate('/lobby' + location.search);
          setCurrentView('lobby');
          return 'lobby';
        }
      }

      console.log('#!#!-WELCOME-CHECK');
      console.log('#!#!-registrationData', registrationData);
      console.log('#!#!-userValid', userValid);
      console.log(
        '#!#!-playerUser?.cookiesAccepted',
        playerUser?.cookiesAccepted,
      );

      // WELCOME VIEW
      if (
        registrationData &&
        //   playerUser?.userConfirmed &&
        userValid &&
        playerUser?.cookiesAccepted &&
        (!onlineStageData?.accessCodeRequired || playerUser?.accessToken)
      ) {
        location.pathname !== '/welcome' &&
          navigate('/welcome' + location.search);
        setCurrentView('welcome');
        return 'welcome';
      }

      console.log('#!#!-REGISTRATION-CHECK');
      console.log('#!#!-urlSearchParams.get(uct)', urlSearchParams.get('uct'));
      console.log('#!#!-registrationOpen', registrationOpen);
      console.log('#!#!-userValid', userValid);

      // REGISTRATION / CONFIRMATION
      if (registrationData) {
        if (urlSearchParams.get('uct')) {
          // CONFIRMATION VIEW - user wants to confirm registration / invitation
          // uct = UserConfirmationToken

          setPlayerUser({ uct: urlSearchParams.get('uct') });
          urlSearchParams.delete('uct');

          // store utc in local
          location.pathname !== '/confirm' &&
            navigate('/confirm?' + urlSearchParams.toString());
          setCurrentView('confirm');
          return 'confirm';
        } else if (registrationOpen && !userValid) {
          // REGISTRATION VIEW - user needs to register
          console.log('SHOULD NAVIGATE', '/registration' + location.search);

          location.pathname !== '/registration' &&
            navigate('/registration' + location.search);
          setCurrentView('registration');
          return 'registration';
        }
      }

      console.log('#!#!-DEFAULT-CHECK');
      // DEFAULT VIEW
      location.pathname !== '/' && navigate('/' + location.search);
      setCurrentView('/');
      return '/';
    }
  };

  //// check for dates
  // check if registration is open
  useInterval(() => {
    if (
      registrationData &&
      !lobbyOpen &&
      new Date().getTime() >= registrationData?.registrationStarts.getTime()
    ) {
      console.log('lobbyOpen', lobbyOpen);
      setRegistrationOpen(true);
      redirectHandler();
    }
  }, 1000);

  // check if lobby is open
  useInterval(() => {
    if (
      playerUi.multiSession &&
      !registrationOpen &&
      new Date().getTime() >= onlineStageData?.starttimeLobby.getTime()
    ) {
      console.log('registrationOpen', registrationOpen);
      setLobbyOpen(true);
      redirectHandler();
    }
  }, 1000);

  // INITIAL REDIRECT
  useEffect(() => {
    if (playerUser) {
      if (
        playerUser.certificate &&
        (!playerUser.memberId || playerUser.memberId === '')
      ) {
        setUserValid(false);
      } else {
        setUserValid(true);
      }
    }
    redirectHandler();
  }, []);

  // side effect
  useEffect(() => {
    if (playerUi) {
      redirectHandler();
      setVcInit(true);
    } else {
      setFeedback({ type: 'loading' });
    }
  }, [playerUi]);

  // check if we have a valid user
  useEffect(() => {
    if (playerUser && playerUser.firstname) {
      // setUserValid(true);
      if (
        playerUser.certificate &&
        (!playerUser.memberId || playerUser.memberId === '')
      ) {
        setUserValid(false);
      } else {
        setUserValid(true);
      }
    }
    if (playerUser?.cookiesAccepted) redirectHandler();
  }, [playerUser]);

  // check if we need to redirect if valid user changes
  useEffect(() => {
    if (userValid) redirectHandler();
  }, [userValid]);

  // check for changes in live data
  useEffect(() => {
    //!!! be more specific on when to update the redirect
    redirectHandler();
  }, [liveData]);

  return { vcInit: vcInit, currentView: currentView };
}
